<template>
  <section class="pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb
        title="Consulta integrações"
        actualPage="Consulta integrações"
      />
    </div>
    <div>
      <div class="header">
        <div class="pt-10">
          <h4>Filtrar:</h4>
        </div>
        <div class="pt-10 filtro">
          <div class="item data">
            <el-date-picker
              v-model="paramModalDate"
              format="dd/MM/yyyy"
              value-format="yyyy/MM/dd"
              type="daterange"
              align="right"
              unlink-panels
              start-placeholder="Data Inicial"
              end-placeholder="Data Final"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <div class="item tipo">
            <el-input
              class="w-full"
              placeholder="Identificador (Opcional)"
              v-model="Identifier"
            ></el-input>
          </div>

          <div class="item tipo">
            <el-select v-model="type" placeholder="Escolha o tipo" clearable>
              <el-option key="PROPOSAL" label="Proposta" value="PROPOSAL">
              </el-option>
              <el-option
                key="POLICYHOLDER"
                label="Tomador"
                value="POLICYHOLDER"
              >
              </el-option>
              <el-option key="BROKER" label="Corretor" value="BROKER">
              </el-option>
              <el-option key="POLICY" label="Apólice" value="POLICY">
              </el-option>
              <el-option key="ENDORSEMENT" label="Endosso" value="ENDORSEMENT">
              </el-option>
              <el-option
                key="CALLBACK_PROPOSAL"
                label="Callback de Proposta"
                value="CALLBACK_PROPOSAL"
              >
              </el-option>
              <el-option
                key="CALLBACK_BROKER"
                label="Callback de  Corretor"
                value="CALLBACK_BROKER"
              >
              </el-option>
              <el-option
                key="CALLBACK_POLICYHOLDER"
                label="Callback de Tomador"
                value="CALLBACK_POLICYHOLDER"
              >
              </el-option>
              <el-option
                key="CALLBACK_ERROR"
                label="Callback de Erro"
                value="ERROR"
              >
              </el-option>
                 <el-option
                key="CALLBACK_ENDORSEMENT"
                label="Callback de Endosso"
                value="CALLBACK_ENDORSEMENT"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="pt-10 botoes text-right">
          <el-button size="mini" round type="danger" @click="limpar()"
            >Limpar</el-button
          >
          <el-button
            v-if="paramModalDate != false && type.length > 0"
            size="mini"
            type="success"
            round
            @click="buscar()"
            >Buscar
          </el-button>
          <el-button
            v-else
            disabled
            size="mini"
            type="success"
            round
            @click="errorInput()"
            >Buscar</el-button
          >
        </div>
        <div class="pt-10">
          <h2 v-if="NumberofRows > 0">{{ NumberofRows }} Resultados</h2>
          <br />
          <hr />
        </div>
      </div>
      <div class="pt-10" v-if="listaIntegracoes">
        <el-table
          :data="listaIntegracoes"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="Identifier"
            label="Identificação da integração"
          >
          </el-table-column>
          <el-table-column label="Tipo da integração">
            <template slot-scope="scope">
              {{ scope.row.IntegrationType === "PROPOSAL" ? "Proposta" : "" }}
              {{
                scope.row.IntegrationType === "POLICYHOLDER" ? "Tomador" : ""
              }}
              {{ scope.row.IntegrationType === "BROKER" ? "Corretor" : "" }}
              {{ scope.row.IntegrationType === "ENDORSEMENT" ? "ENDOSSO" : "" }}
              {{ scope.row.IntegrationType === "POLICY" ? "APÓLICE" : "" }}

              {{
                scope.row.IntegrationType === "CALLBACK_PROPOSAL"
                  ? "Callback de Proposta"
                  : ""
              }}
              {{
                scope.row.IntegrationType === "CALLBACK_BROKER"
                  ? "Callback de  Corretor"
                  : ""
              }}
              {{
                scope.row.IntegrationType === "CALLBACK_POLICYHOLDER"
                  ? "Callback de Tomador"
                  : ""
              }}
              {{
                scope.row.IntegrationType === "CALLBACK_ERROR"
                  ? "Callback de Erro"
                  : ""
              }}
              {{
                scope.row.IntegrationType === "CALBACK_ENDORSEMENT"
                  ? "Callback de Endosso"
                  : ""
              }}
              {{ scope.row.IntegrationType === "ERROR" ? "Erro" : "" }}
              {{
                scope.row.IntegrationType === "CALLBACK_ENDORSEMENT"
                  ? "Callback Endosso"
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="Data da integração">
            <template slot-scope="scope">
              {{ new Date(scope.row.CreateDate) | moment("DD/MM/YYYY") }}
            </template>
          </el-table-column>
          <el-table-column align="right">
            <template slot-scope="scope">
              <el-button
                v-if="(scope.row || {}).Archives"
                :disabled="(scope.row || {}).Archives <= 0"
                size="mini"
                round
                type="primary"
                @click="modalDocumentos(scope)"
                ><i class="onpoint-files icon-font" aria-hidden="true"></i
              ></el-button>
              <el-button
                size="mini"
                round
                type="primary"
                @click="exibirDetalhes(scope)"
                >Visualizar JSON</el-button
              >
              <el-button
                size="mini"
                type="success"
                round
                @click="Reenviar(scope)"
                >Reenviar</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pt-10">
        <vs-pagination
          v-if="numberOfPages > 1"
          :total="numberOfPages"
          v-model="currentPage"
        ></vs-pagination>
      </div>

      <vs-popup :active.sync="detalhesModal" :title="'Detalhes da Integração'">
        <div class="vx-row">
          <div class="vx-col w-full">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="Request" name="first" v-if="Request">
                <div class="text-right">
                  <el-button
                    type="primary"
                    round
                    @click="copyToClipboard(Request)"
                  >
                    Copiar JSON</el-button
                  >
                </div>
                <tree-view
                  id="element-to-print"
                  :data="Request"
                  :options="{ maxDepth: 5, rootObjectKey: 'JSON' }"
                ></tree-view>
              </el-tab-pane>
              <el-tab-pane label="Response" name="second" v-if="Response">
                <div class="text-right">
                  <el-button
                    type="primary"
                    round
                    @click="copyToClipboard(Response)"
                  >
                    Copiar JSON</el-button
                  >
                </div>
                <tree-view
                  id="element-to-print"
                  :data="Response"
                  :options="{ maxDepth: 5, rootObjectKey: 'JSON' }"
                ></tree-view>
              </el-tab-pane>
              <el-tab-pane label="Exception" name="terceiro" v-if="Exception">
                <div class="text-right">
                  <el-button
                    type="primary"
                    round
                    @click="copyToClipboard(Exception)"
                  >
                    Copiar JSON</el-button
                  >
                </div>
                <tree-view
                  id="element-to-print"
                  :data="Exception"
                  :options="{ maxDepth: 5, rootObjectKey: 'JSON' }"
                ></tree-view>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :active.sync="modalDocumento"
        :title="'Documentos da Integração'"
      >
        <vs-table
          :data="documentos"
          :hoverFlat="true"
          noDataText="Nenhuma registro encontrado"
        >
          <template slot="thead">
            <vs-th>ID</vs-th>
            <vs-th>Nome do arquivo</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].Id" class="text-left">
                {{ item.Id || "-" }}
              </vs-td>

              <vs-td :data="item.Filename" class="text-left">
                {{ item.Filename }}
              </vs-td>

              <vs-td class="text-right">
                <el-button
                  size="mini"
                  round
                  :type="item.Filepath ? 'primary' : 'danger'"
                  @click="openInNewTab(item.Filepath)"
                  ><i class="onpoint-link icon-font" aria-hidden="true"></i
                ></el-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mapGetters, mapActions } from "vuex";
import onPointIcon from "@/components/onPointIcons";
export default {
  components: { Breadcrumb, onPointIcon },
  directives: {},
  data() {
    return {
      activeName: "first",
      listaIntegracoes: "",
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      NumberofRows: 0,
      searchValue: "",
      loading: false,
      Request: false,
      Response: false,
      Exception: false,
      documentos: {},
      detalhesModal: false,
      paramModalDate: false,
      modalDocumento: false,
      Identifier: "",
      type: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "1 dia",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "7 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "30 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "90 dias",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarHitoricoConsultasSegurado();
    },
  },
  mounted() {
    // this.listarHitoricoConsultasSegurado();
  },
  computed: {},
  methods: {
    ...mapActions("pessoa-module", [
      "getPersonIntegrationHistory",
      "resentIntegration",
    ]),
    async copyToClipboard(mytext) {
      let objeto = JSON.stringify(mytext);
      try {
        await navigator.clipboard.writeText(objeto);
        this.$notify({
          title: "Sucesso",
          message: "JSON copiado!",
          type: "success",
          position: "bottom-right",
        });
      } catch ($e) {}
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.Error) {
        return "warning-row";
      }
    },

    buscar() {
      this.listarHitoricoConsultasSegurado();
    },

    errorInput() {
      this.$notify({
        title: "Aviso!",
        message: "Preencha a data e o tipo para continuar a busca!",
        type: "warning",
        position: "top-right",
      });
    },

    limpar() {
      this.Identifier = "";
      this.type = "";
      this.paramModalDate = false;
      this.listaIntegracoes = false;
      this.currentPage = 1;
      this.NumberofRows = 0;
      this.numberOfPages = 0;
    },
    openInNewTab(url) {
      if (url) {
        window.open(url, "_blank", "noreferrer");
      } else {
        this.$notify({
          title: "Aviso!",
          message: "Url não encontrada ou inexistente",
          type: "warning",
        });
      }
    },
    exibirDetalhes(row) {
      this.Request = false;
      this.Response = false;
      this.Exception = false;
      this.detalhesModal = true;
      if (row.row.Request) {
        this.activeName = "first";
        this.Request = JSON.parse(row.row.Request);
      } else {
        this.activeName = "second";
      }
      if (row.row.Response) {
        this.Response = JSON.parse(row.row.Response);
      }
      if (row.row.Response) {
        this.Response = JSON.parse(row.row.Response);
      }
      if (row.row.Exception) {
        this.Exception = JSON.parse(row.row.Exception);
      }
    },
    modalDocumentos(row) {
      this.modalDocumento = true;
      this.documentos = row.row.Archives;
    },
    async listarHitoricoConsultasSegurado() {
      await this.$onpoint.loading( async () => {
        return await this.getPersonIntegrationHistory({
          StartDate: this.paramModalDate[0],
          EndDate: this.paramModalDate[1],
          Type: this.type,
          Identifier: this.Identifier,
          IgnoreArchivesListEmpty: false,
          IgnoreCompanyId: true,
          currentPage: this.currentPage,
          rowsPerPage: 10,
        })
          .then((response) => {
            this.listaIntegracoes = response.results;
            this.numberOfPages = response.NumberOfPages;
            this.NumberofRows = response.NumberofRows;
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },
    async Reenviar(data) {
      this.$confirm("Deseja continuar o reenvio da integração?", {
        confirmButtonText: "Continuar",
        cancelButtonText: "Voltar",
      })
        .then(async () => {
          await this.$onpoint.loading(async () => {
            return await this.resentIntegration({
              Id: data.row.Id,
              Identifier: data.row.Identifier,
              IntegrationType: data.row.IntegrationType,
              request: data.row.Request,
              response: data.row.Response,
            })
              .then((response) => {
                this.$notify({
                  title: "Sucesso",
                  message: "Reenvio concluído!",
                  type: "success",
                  position: "top-right",
                });
              })
              .catch((err) => {
                this.$onpoint.errorModal(err.response.data.Message);
              });
          });
        })
        .catch(() => {
          console.log("cancelou");
        });
    },
  },
  created() {
    this.$appInsights.trackPageView({
      name: "integracoes",
      url: window.location.href
    });
  }
};
</script>


<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}
</style>

<style lang="scss">
.el-table .warning-row {
  background: #ffecec;
}
.el-table .success-row {
  background: #54e008;
}
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}
.filtro {
  display: flex;
  .data {
    width: 70%;
  }
  .tipo {
    width: 30%;
    padding-left: 15px;
  }
}

.icon-font::before{
  position: relative;
  top: 2px;
}
</style>
